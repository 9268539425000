<template>
<div class="h-screen flex w-full bg-img">
      <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
          <vx-card>
              <div slot="no-body" class="full-page-bg-color">
                  <div class="vx-row">
                      <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                          <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto">
                      </div>
                      <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                        <div v-if="tokenExpired || !tokenPrevalidated" class="p-8">
                            <div class="vx-card__title mb-8 w-full">
                                <tp-cardtext v-bind:min-height='165'>
                                  <p>Sorry, the password reset link has expired. Please request a new password reset email.</p>
                                </tp-cardtext>
                                <vs-button type="border" to="/forgottenpassword" class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6">Forgot Password</vs-button>
                            </div>
                        </div>
                        <div v-if="resetSuccessful" class="p-8">
                            <div class="vx-card__title mb-8 w-full">
                                <h4 class="mb-4">Password Has Been Reset</h4>
                                <tp-cardtext v-bind:min-height='150'>
                                  <p>Your password has been updated. Please login to your account using your new password</p>
                                </tp-cardtext>
                                <vs-button type="border" to="/login" class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6">Login</vs-button>
                            </div>
                        </div>
                        <div v-if="tokenPrevalidated && !resetSuccessful" class="p-8">
                            <div class="vx-card__title mb-8">
                                <h4 class="mb-4">Reset Password</h4>
                                <p>Please enter your new password</p>
                            </div>
                              <vs-input type="password"
                                v-validate="'required'"
                                data-vv-validate-on="blur"
                                label-placeholder="New password"
                                name="newPassword"
                                v-model="newPassword"
                                class="w-full" 
                              />
                            <span class="text-danger text-sm">{{ errors.first('newPassword') }}</span>
                            
                            <vs-input type="password"
                              v-validate="'required'"
                              data-vv-validate-on="blur"
                              label-placeholder="Confirm new password"
                              name="confirmNewPassword"
                              v-model="confirmNewPassword"
                              class="w-full mt-6" 
                            />
                            <span class="text-danger text-sm">{{ errors.first('confirmNewPassword') }}</span>

                            <div class="w-full mb-6"></div>
                            <vs-button 
                              class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6" 
                              @click="resetPassword">Update Password
                            </vs-button>

                        </div>
                      </div>
                  </div>
              </div>
          </vx-card>
      </div>
  </div>
</template>

<script>
import TpCardtext from '../../components/travio-pro/TpCardtext'

export default {
  components: {
    TpCardtext
  },
  data() {
    return {
      resetToken: "",
      newPassword: "",
      confirmNewPassword: "",
      resetSuccessful: false,
      tokenExpired: false,
      tokenPrevalidated: false
    }
  },
  methods: {
    resetPassword() {
      this.$vs.loading();
      this.$http.post('api/auth/resetPassword', { 
          resetToken : this.resetToken,
          newPassword: this.newPassword,
          confirmNewPassword: this.confirmNewPassword
        }).then(response => {
          this.resetSuccessful = true;
        })
        .catch(error => {
          this.$vs.notify({
            time: 4000,
            title: 'Reset Password',
            text: `Unable to reset password ${error.response.data.title}`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
        })
        .finally(()=> this.$vs.loading.close());
    }
  },
  mounted() {
    this.$http.get(`api/auth/resetPassword?resetToken=${this.$route.query.resetToken}`)
      .then(response => {
        this.tokenPrevalidated = true;
      })
      .catch(error => {
        console.log(error)
      });
  },
  created () {
    this.resetToken = this.$route.query.resetToken
  },
  watch: {
    $route(to, from) {
      this.resetToken = from.query.resetToken
    }
  }
}
</script>

<style>

</style>